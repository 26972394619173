export default {
    checkPassword (rule, value, callback) {
        if (!/(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^0-9A-Za-z]).{8,20}/.test(value)) {
            callback(new Error('密码格式长度8到20个字符，并包含数字大小写字母及特殊字符'));
        } else {
            callback();
        }
    },
    checkConfirmPassword (rule, value, callback) {
        if (value !== this.form.newpassword ) {
            callback(new Error('两次输入密码不一致'));
        } else {
            callback();
        }
    },
    checkMobileNumber (rule, value, callback) {
        if (!/^1\d{10}$/.test(value)) {
            callback(new Error('手机号码应为1开头的11位数字'));
        } else {
            callback();
        }
    },
    checkInt (rule, value, callback) {
        if (!/^([1-9]\d*|0)$/.test(value)) {
            callback(new Error('应为正整数'));
        } else if (value.length > 12) {
            callback(new Error('位数不能大于12位'));
        } else {
            callback();
        }
    },
    checkNumber (rule, value, callback) {
        if (!/^[+-]?([1-9]\d*|0)(\.\d+)?$/.test(value)) {
            callback(new Error('应为数字'));
        } else {
            let arr = value.split('.');
            if (arr[0].length > 12) {
                callback(new Error('整数位位数不能大于12位'));
            } else if (arr.length > 1 && arr[1].length > 4) {
                callback(new Error('小数位位数不能多于4位'));
            } else {
                callback();
            }
        }
    },
    checkMoney(rule, value, callback) {
        if (!/^[+-]?([1-9]\d*|0)(\.\d+)?$/.test(value)) {
            callback(new Error('应为数字'));
        } else {
            let arr = value.split('.');
            if (arr[0].length > 12) {
                callback(new Error('整数位位数不能大于12位'));
            } else if (arr.length > 1 && arr[1].length > 2) {
                callback(new Error('小数位位数不能多于2位'));
            } else {
                callback();
            }
        }
    },
    checkRange(rule, value, callback) {
        if (value < rule.range[0] || value > rule.range[1]) {
            callback(new Error('数值范围为' + rule.range[0] + '~' + rule.range[1]));
        }

        callback();
    },
    validateField(keys, callback) {
        let count = 0;
        let done = false;
       
        this.$refs.form.validateField(keys, function (errorMessage) {
            if (done) {
                return;
            }

            if (errorMessage == '') {
                count++;
            } else {
                done = true;
                callback(false);
            }

            if (count == keys.length) {
                done = true;
                callback(true);
            }
        });
    },
    checkUrl(rule, value, callback) {
        if (!rule.required && (value == '' || value == null)) {
            callback();
            return;
        }
        if (!/^https?:\/\/.+$/.test(value)) {
            callback(new Error('url格式错误'));
        } else {
            callback();
        }
    },
};