<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px" :show-message="true" class="form-col2">
            <el-row :gutter="20">
                <el-col :span="24">
                     <el-form-item label="费用类型名称" prop="name">
                        <el-input type="text" v-model="form.name" size="small" maxlength="50" @blur="fieldTrim(form, 'name')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                     <el-form-item label="押金" prop="deposit">
                        <el-input type="text" v-model="form.deposit" size="small" maxlength="50" @blur="fieldTrim(form, 'deposit')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                     <el-form-item label="租金" prop="rent">
                        <el-input type="text" v-model="form.rent" size="small" maxlength="50" @blur="fieldTrim(form, 'rent')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'DemoBaseAdd',
    data(){
        return {
            form: {
                project_id: '',
                name: '',
                deposit: '',
                rent: '',
            },
            rules: {
                name: [{required: true, message: '费用类型名称不能为空', trigger: 'blur'}],
                deposit: [
                    {required: true, message: '押金不能为空', trigger: 'blur'},
                    {required: true, validator: this.checkMoney, trigger: 'blur'},
                ],
                rent: [
                    {required: true, message: '租金不能为空', trigger: 'blur'},
                    {required: true, validator: this.checkMoney, trigger: 'blur'},
                ],
            },
            submitting: false
        }
    },
    created() {
        
    },
    methods: {
        ...common,
        ...verify,
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    http.post('v1/buildGood/add', this.form).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('新增成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
    }
};
</script>

<style scoped>
</style>