<template>
    <div class="dialog-inner form-col2" style="padding-top:0;">
        <div class="form-group-title"><h2>基本信息</h2></div>
        <el-row :gutter="20">
            <el-col :span="12">
                <div class="data-item">
                    <div class="label">工号：</div>
                    <div class="text">{{data.number}}</div>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="data-item">
                    <div class="label">用户名：</div>
                    <div class="text">{{data.userName}}</div>
                </div>
            </el-col>
        </el-row>
        <div class="form-group-title"><h2>个人信息</h2></div>
        <el-row :gutter="20">
            <el-col :span="12">
                <div class="data-item">
                    <div class="label">姓名：</div>
                    <div class="text">{{data.name}}</div>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="data-item">
                    <div class="label">性别：</div>
                    <div class="text">{{data.sex === 1 ? '男' : '女'}}</div>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="24">
                <div class="data-item">
                    <div class="label">手机号：</div>
                    <div class="text">{{data.mobileNumber}}</div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'DemoBaseDetail',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            data: {
                number: '',
                name: '',
                userName: '',
                sex: '',
                mobileNumber: '',
            },
            submitting: false
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        ...common,
        ...verify,
        loadData() {
            this.dataLoading = true;
            let loading = this.load();
            http.get(http.MOCK_BASE_API_URL + 'demo/' + this.params.id).then(req => {
                loading.close();
                if (req.data.code == http.SUCCESS) {
                    this.data = req.data.data;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                loading.close();
                this.httpCatch(err);
            });
        },
    }
};
</script>

<style scoped>
</style>