<template>
    <div class="frame-content">
        <div class="frame-content-wrap">
            <!-- <div class="filter">
                <div class="inline">
                    <el-input v-model="filters.name" placeholder="姓名" size="small"></el-input>
                </div>
                <div class="inline ml-8">
                    <el-select v-model="filters.sex" placeholder="性别" size="small">
                        <el-option label="男" value="1"></el-option>
                        <el-option label="女" value="2"></el-option>
                    </el-select>
                </div>
                <div class="inline ml-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div> -->
            <div class="datatable-wrap">
                <div class="topbar">
                    <div class="inline">
                        <el-button type="primary" size="small" @click="handleAdd" :disabled="$store.state.org.type != 2">新增</el-button>
                    </div>
                    <!-- <div class="inline ml-8">
                        <el-button type="danger" size="small" @click="handleDeleteAll">删除</el-button>
                    </div> -->
                </div>
                <div class="datatable">
                    <el-table
                        ref="curRuleTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            label="序号"
                            align="center"
                            width="80">
                            <template slot-scope="scope">
                                <span>{{ (curPageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="费用类型名称"
                            min-width="200">
                            <template slot-scope="scope">
                                <template v-if="!tableStateData[scope.row.id].edit">{{ scope.row.name }}</template>
                                <el-input v-else v-model="tableStateData[scope.row.id].name" size="mini"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="deposit"
                            label="押金">
                            <template slot-scope="scope">
                                <template v-if="!tableStateData[scope.row.id].edit">{{ scope.row.deposit }}{{ scope.row.depositUnit }}</template>
                                <el-input v-else v-model="tableStateData[scope.row.id].deposit" :placeholder="scope.row.depositUnit" size="mini"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="rent"
                            label="租金">
                            <template slot-scope="scope">
                                <template v-if="!tableStateData[scope.row.id].edit">{{ scope.row.rent }}{{ scope.row.rentUnit }}</template>
                                <el-input v-else v-model="tableStateData[scope.row.id].rent" :placeholder="scope.row.rentUnit" size="mini"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="status"
                            label="状态（禁用/启用）"
                            align="center"
                            width="160">
                            <template slot-scope="scope">
                                <el-switch
                                    v-model="scope.row.status"
                                    :active-color="scope.row.type === 1 ? '#999' : '#13ce66'"
                                    inactive-color="#ff4949"
                                    :disabled="scope.row.type === 1"
                                    @change="handleStatusChange($event, scope.row)">
                                </el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="action"
                            label="操作"
                            align="center"
                            width="220">
                            <template slot-scope="scope">
                                <el-button
                                    v-if="!tableStateData[scope.row.id].edit"
                                    size="mini"
                                    type="primary"
                                    @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                <el-button
                                    v-if="tableStateData[scope.row.id].edit"
                                    size="mini"
                                    type="primary"
                                    @click="handleSave(scope.$index, scope.row)">保存</el-button>
                                <el-button
                                    v-if="tableStateData[scope.row.id].edit"
                                    size="mini"
                                    type="primary"
                                    @click="handleCancel(scope.$index, scope.row)">取消</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @curRule-change="handleCurRuleChange"
                        :background="true"
                        :curRule-page="pageNum"
                        :page-sizes="[50, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>

            <el-dialog
                title="详情"
                v-if="detailDialog.create"
                :visible.sync="detailDialog.visible"
                :close-on-click-modal="false"
                @closed="handleDetailClosed"
                class="custom-dialog big-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <RuleDetail :params="detailDialog.params"></RuleDetail>
            </el-dialog>

            <el-dialog
                title="新增费用类型"
                v-if="addDialog.create"
                :visible.sync="addDialog.visible"
                :close-on-click-modal="false"
                @closed="handleAddClosed"
                class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <RuleAdd @done="handleAddDone"></RuleAdd>
            </el-dialog>
            
            <el-dialog
                title="编辑"
                v-if="editDialog.create"
                :visible.sync="editDialog.visible"
                :close-on-click-modal="false"
                @closed="handleEditClosed"
                class="custom-dialog big-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <RuleEdit :params="editDialog.params" @done="handleEditDone"></RuleEdit>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'
import RuleDetail from './RuleDetail'
import RuleAdd from './RuleAdd'
import RuleEdit from './RuleEdit'

export default {
    name: 'DemoRule',
    components: {
        RuleDetail,
        RuleAdd,
        RuleEdit
    },
    data() {
        return {
            filters: {
                name: '',
                sex: '',
            },

            tableData: [],
            tableStateData: {},

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            curPageNum: 1,

            tableLoading: false,

            detailDialog: {
                create: false,
                visible: false,
                params: {},
            },

            addDialog: {
                create: false,
                visible: false,
            },

            editDialog: {
                create: false,
                visible: false,
                params: {},
            },
        };
    },
    created () {
        this.loadTable();
    },
    methods: {
        ...common,
        // 加载表格数据
        loadTable() {
            let params = {...this.filters};

            params.pageNum = this.pageNum;
            params.pageSize = this.pageSize;

            this.tableLoading = true;
            http.get('v1/buildGood/page', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    this.tableData = req.data.data.records;

                    this.tableData.forEach(item => {
                        item.status = item.status == 1;
                        item.deposit = this.toFixed(item.deposit, 2);
                        item.rent = this.toFixed(item.rent, 2);
                        item.depositUnit = "元";
                        item.rentUnit = "元/间/月";
                    });

                    this.tableStateData = {};
                    this.tableData.forEach(row => {
                        this.tableStateData[row.id] = {
                            name: row.name,
                            rent: row.rent,
                            deposit: row.deposit,
                            edit: false,
                        };
                    });
                   
                    this.totalNum = req.data.data.total;
                    this.curPageNum = this.pageNum;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurRuleChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },

        // 添加弹窗口
        handleAdd() {
            this.addDialog = { create: true, visible: false };
            this.$nextTick(() => {
                this.addDialog = { create: true, visible: true };
            });
        },
        handleAddDone() {
            this.addDialog = { visible: false };
            this.loadTable();
        },
        handleAddClosed() {
            this.addDialog = { create: false, visible: false };
        },

        // 编辑
        handleEdit(index, row) {
            let item = { ...this.tableStateData[row.id], edit: true };
            this.tableStateData[row.id] = item;
            this.tableStateData = { ...this.tableStateData };
        },
        handleSave(index, row) {
            let { name, rent, deposit } = this.tableStateData[row.id];
            http.post('v1/buildGood/edit', { id: row.id, name: name, rent: rent, deposit: deposit }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.loadTable();
                    this.showSuccess('保存成功！');
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleCancel(index, row) {
            let item = { ...this.tableStateData[row.id], edit: false };
            this.tableStateData[row.id] = item;
            this.tableStateData = { ...this.tableStateData };
        },

        handleStatusChange(value, row) {
            let status = 0;
            if (value) {
                status = 1;
            }
            let text = status == 1 ? '启用' : '禁用';

            http.post('v1/buildGood/status', { id: row.id, status: status }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.loadTable();
                    this.showSuccess(text + '成功！');
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
    }
}
</script>

<style scoped>
</style>
